import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtm from '@gtm-support/vue2-gtm';
import VueGtag from 'vue-gtag';

Vue.use(VueGtm, {
	id: 'GTM-TD7RBN5',
	defer: false,
	compatibility: false,
	enabled: true,
	debug: false,
	loadScript: true,
	vueRouter: router,
	trackOnNextTick: false,
});

Vue.use(
	VueGtag,
	{
		config: {
			id: 'AW-451827930',
			params: {
				send_page_view: false,
			},
		},
		includes: [
			{
				id: 'UA-124821034-1',
				params: {
					send_page_view: false,
				},
			},
			{
				id: 'G-CYXF981YFQ',
				params: {
					send_page_view: false,
				},
			},
		],
	},
	router,
);
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
