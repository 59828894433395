<script>
/* eslint-disable */
export default {
	name: 'nav_header',
	data() {
		return {
			isActiveMenu: false,
		};
	},
	computed: {
		hostUrl() {
			return this.$store.state.hostUrl;
		},
		collectionIdArr() {
			return this.$store.state.collectionIdArr;
		},
		comparisonIdArr() {
			return this.$store.state.comparisonIdArr;
		},
	},
	methods: {
		gtag(target) {
			let vm = this;
			switch (target) {
				case 'logo':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_home',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'search':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_search',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'sale':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_sale',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});

					fbq('trackSingle', '436643020262829', 'AddToWishlist');
					fbq('trackSingle', '2320616348184878', 'AddToWishlist');
					_lt(
						'send',
						'cv',
						{
							type: 'SellingBTN',
						},
						['f318243c-e326-4eae-ab95-2a016ce79687'],
					);
					break;
				case 'benefits':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_benefits',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'collection':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_collection',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'compare':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_compare',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'dlo':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_navigation_dlo',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
			}
		},
		menuToggle() {
			// console.log("menuToggle")
			let vm = this;
			vm.isActiveMenu = !vm.isActiveMenu;
		},
	},
};
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped>
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
</style>
