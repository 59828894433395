<script>
export default {
	name: 'nav_footer',
	props: {
		isActiveFaq: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		gtag(target) {
			let vm = this;
			switch (target) {
				case 'benefits':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_benefits',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					if (vm.$route.name !== 'Home') {
						vm.$router.replace('/#rights');
					} else {
						const element = document.getElementById('benefits');
						element.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
							inline: 'nearest',
						});
					}
					break;
				case 'FAQ':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_FAQ',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'officialsite':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_officialsite',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'mini':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_mini',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'disclaimer':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_disclaimer',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'FB':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_FB',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'IG':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_IG',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'YOUTUE':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_YOUTUE',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
				case 'LINE':
					vm.$gtag.event('CLICK', {
						event_category: 'click',
						event_label: 'Click_footer_LINE',
						send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
					});
					break;
			}
		},
		lightboxToggle() {
			// console.log("lightboxToggle")
			let vm = this;
			vm.$emit('update:isActiveFaq', true);
		},
	},
};
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped>
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
</style>
