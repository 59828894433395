<script>
/* eslint-disable */
export default {
	name: 'compare_fixed',
	props: {
		nowPage: {
			type: null,
			required: true,
		},
	},
	computed: {
		hostUrl() {
			return this.$store.state.hostUrl;
		},
		comparisonIdArr() {
			return this.$store.state.comparisonIdArr;
		},
		comparisonInfoArr() {
			return this.$store.state.comparisonInfoArr;
		},
	},
	methods: {
		gtag() {
			let vm = this;
			vm.$gtag.event('CLICK', {
				event_category: 'click',
				event_label: 'Click_collection_compare_start',
				send_to: ['UA-124821034-1', 'G-CYXF981YFQ'],
			});
		},
		removeComparison(id) {
			// console.log("removeComparison",id)
			let vm = this;
			vm.comparisonInfoArr.forEach((element, index) => {
				if (element.id === id) {
					vm.$store.dispatch('GETCOMPARISON', { id, element });
				}
			});
		},
	},
};
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped>
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
</style>
